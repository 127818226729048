import { authHeader, globalVariable, handleResponse } from '../helpers';
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const printService = {
    printTable1Data
};

/**
 *Print table 1 data
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {boolean} [isAutoComplete=false]
 * @param {*} projectData - projectid, project_reportid
 * @returns
 */
function printTable1Data(skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false, projectData = {}) {
    let data = {
        fields: sfields,
        values: sValues,
        isAutoComplete,
        projectData: projectData,
        print: true
    };

    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/table1/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
}