import { reportTablesConstants } from '../constants';
import { DefaultFields } from '../containers/Reports/tabs/Table2Routes/DefaultDatas';

const table1Datas = (sortName, pageDefaults = {}) => {
    return {
        data: [],
        page: {
            ...pageDefaults,
            fieldFilters: {
                headerCols: [],
                fieldCols: [sortName],
                searchCols: []
            }
        },
        validations: null,
        isLoading: false
    };
}

const initialState = {
    table1: {
        ...table1Datas('unit.common_id')
    },
    table2: {
        data: {},
        validations: null,
        isLoading: false
    },
    table3: {
        data: null,
        validations: null,
        isLoading: false
    },
    table4: {
        data: null,
        validations: null,
        isLoading: false,
        isTable4Exists: null
    },
    validations: {
        data: {
            table1: 1,
            table2: 1,
            table3: 1,
            table4: 1,
        },
        isLoading: false
    },
    formValidations: {
        table1: {
            data: null
        }
    },
    validationIssues: {
        data: {
            table1: 1,
            table2: 1,
            table3: 1,
            table4: 1,
            issues: [],
            table1Issues: [],
            table2Issues: [],
            table3Issues: [],
            table4Issues: [],
            maxError: 0,
            isPrevSubmitted: false
        }
    },
    reportSubmission: {
        isLoading: false
    },
    updatedModified: {
        updatedAt: null,
        modifiedBy: null,
        table: null
    }

};

export function reportTables(state = { ...initialState }, action) {

    switch (action.type) {

        // CLEAR TABLES VALUES
        case reportTablesConstants.CLEAR_TABLES_REQUEST:
            return {
                ...state,
                table1: {
                    ...table1Datas('unit.common_id', action.pageDefaults || {})
                }
            }

        //TABLE 1
        case reportTablesConstants.GET_TABLE1_DATA_REQUEST:
            return {
                ...state,
                table1: {
                    ...state.table1,
                    isLoading: true
                }
            }

        case reportTablesConstants.GET_TABLE1_DATA_SUCCESS:
            return {
                ...state,
                table1: {
                    ...state.table1,
                    isLoading: false,
                    ...action.table1
                },
                updatedModified: {
                    table: "table1"
                }
            }

        case reportTablesConstants.GET_TABLE1_DATA_FAILURE:
            return {
                ...state,
                table1: {
                    ...state.table1,
                    isLoading: false,
                    error: action.error
                }

            }

        //TABLE 2
        case reportTablesConstants.GET_TABLE2_DATA_REQUEST:
            return {
                ...state,
                table2: {
                    ...state.table2,
                    data: {},
                    isLoading: true
                }
            }

        case reportTablesConstants.GET_TABLE2_DATA_SUCCESS:
            return {
                ...state,
                table2: {
                    ...state.table2,
                    isLoading: false,
                    data: {
                        data: {
                            ...DefaultFields,
                            ...action.table2.data
                        },
                        desc: action.table2.desc
                    },
                    validations: action.table2.validations
                },
                updatedModified: {
                    updatedAt: action.table2.data ? action.table2.data.updatedAt || action.table2.data.createdAt : null,
                    modifiedBy: action.table2.data ? action.table2.data.modifiedBy : null
                }
            }

        case reportTablesConstants.GET_TABLE2_DATA_FAILURE:
            return {
                ...state,
                table2: {
                    ...state.table2,
                    isLoading: false,
                    error: action.error
                }
            }
        //Table 2 Update
        case reportTablesConstants.GET_TABLE2_UPDATE_REQUEST:
            return {
                ...state,
                table2: {
                    ...state.table2,
                    isLoading: true
                }
            }

        case reportTablesConstants.GET_TABLE2_UPDATE_SUCCESS:
            return {
                ...state,
                table2: {
                    ...state.table2,
                    isLoading: false
                },
                updatedModified: {
                    // updatedAt: action.table2.data ? action.table2.data.updatedAt || action.table2.data.createdAt : null,
                    // modifiedBy: { ...state.table2.data.data.modifiedBy }
                    updatedAt: state.table2.data.data!==undefined?state.table2.data.data.updatedAt:new Date().toISOString,
                    modifiedBy: state.table2.data.data!==undefined?state.table2.data.data.modifiedBy:null
                }
            }

        case reportTablesConstants.GET_TABLE2_UPDATE_FAILURE:
            return {
                ...state,
                table2: {
                    ...state.table2,
                    isLoading: false,
                    error: action.error
                }
            }

        //TABLE 3
        case reportTablesConstants.GET_TABLE3_DATA_REQUEST:
            return {
                ...state,
                table3: {
                    ...state.table3,
                    data: null,
                    isLoading: true
                }
            }

        case reportTablesConstants.GET_TABLE3_DATA_SUCCESS:
            return {
                ...state,
                table3: {
                    ...state.table3,
                    isLoading: false,
                    data: action.table3.data,
                    validations: action.table3.validations
                },
                updatedModified: {
                    updatedAt: action.table3.data ? action.table3.data.updatedAt || action.table3.data.createdAt : null,
                    modifiedBy: action.table3.data ? action.table3.data.modifiedBy : null
                }
            }

        case reportTablesConstants.GET_TABLE3_DATA_FAILURE:
            return {
                ...state,
                table3: {
                    ...state.table3,
                    isLoading: false,
                    error: action.error
                }
            }

        //Table 3 Update
        case reportTablesConstants.GET_TABLE3_UPDATE_REQUEST:
            return {
                ...state,
                table3: {
                    ...state.table3,
                    isLoading: true
                }
            }

        case reportTablesConstants.GET_TABLE3_UPDATE_SUCCESS:
            return {
                ...state,
                table3: {
                    ...state.table3,
                    isLoading: false,
                    data: action.table3
                }
                // updatedAt: state.table3.data.data.updatedAt,
            }

        case reportTablesConstants.GET_TABLE3_UPDATE_FAILURE:
            return {
                ...state,
                table3: {
                    ...state.table3,
                    isLoading: false,
                    error: action.error
                }
            }

        //TABLE 4   
        case reportTablesConstants.GET_TABLE4_DATA_REQUEST:
            return {
                ...state,
                table4: {
                    ...state.table4,
                    data: null,
                    isLoading: true
                }
            }


        case reportTablesConstants.GET_TABLE4_DATA_SUCCESS:
            return {
                ...state,
                table4: {
                    ...state.table4,
                    isLoading: false,
                    data: action.table4,
                    validations: action.table4.validations
                },
                updatedModified: {
                    updatedAt: action.table4 ? action.table4.updatedAt || action.table4.createdAt : null,
                    modifiedBy: action.table4 ? action.table4.modifiedBy : null
                }
            }

        case reportTablesConstants.GET_TABLE4_DATA_FAILURE:
            return {
                ...state,
                table4: {
                    ...state.table4,
                    isLoading: false,
                    error: action.error
                }
            }

        //Validations   
        case reportTablesConstants.GET_REPORT_TABLES_VALIDATION_REQUEST:
            return {
                ...state,
                validations: {
                    ...state.validations,
                    isLoading: true
                }
            }


        case reportTablesConstants.GET_REPORT_TABLES_VALIDATION_SUCCESS:
            return {
                ...state,
                validations: {
                    ...state.validations,
                    isLoading: false,
                    data: { ...action.validations }
                }
            }

        case reportTablesConstants.GET_REPORT_TABLES_VALIDATION_FAILURE:
            return {
                ...state,
                validations: {
                    ...state.validations,
                    isLoading: false,
                    error: action.error
                }
            }

        // form validations
        case reportTablesConstants.GET_TABLE_1_ERROR_REQUEST:
            return {
                ...state,
                formValidations: {
                    ...state.formValidations,
                    table1: {
                        data: null
                    },
                    isLoading: true
                }
            }


        case reportTablesConstants.GET_TABLE_1_ERROR_SUCCESS:
            return {
                ...state,
                formValidations: {
                    ...state.formValidations,
                    table1: {
                        data: action.validationData
                    },
                    isLoading: false
                }
            }

        case reportTablesConstants.GET_TABLE_1_ERROR_FAILURE:
            return {
                ...state,
                formValidations: {
                    ...state.formValidations,
                    error: action.error,
                    isLoading: false
                }
            }

        // Validation Issues
        case reportTablesConstants.GET_VALIDATION_ISSUES_REQUEST:
            return {
                ...state,
                validationIssues: {
                    // ...initialState.validationIssues,
                    ...state.validationIssues
                },
                isLoading: true
            }


        case reportTablesConstants.GET_VALIDATION_ISSUES_SUCCESS:
            return {
                ...state,
                validationIssues: action.data,
                isLoading: false
            }

        case reportTablesConstants.GET_VALIDATION_ISSUES_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }

        // report submission
        case reportTablesConstants.SUBMIT_TABLE_ONE_REQUEST:
        case reportTablesConstants.UNSUBMIT_TABLE_ONE_REQUEST:
            return {
                ...state,
                reportSubmission: {
                    isLoading: true
                }
            }


        case reportTablesConstants.SUBMIT_TABLE_ONE_SUCCESS:
        case reportTablesConstants.UNSUBMIT_TABLE_ONE_SUCCESS:
            return {
                ...state,
                reportSubmission: {
                    isLoading: false
                }
            }

        case reportTablesConstants.SUBMIT_TABLE_ONE_FAILURE:
        case reportTablesConstants.UNSUBMIT_TABLE_ONE_FAILURE:
            return {
                ...state,
                reportSubmission: {
                    isLoading: false
                }
            }

        // Refresh validations
        case reportTablesConstants.GET_REFRESH_VALIDATIONS_REQUEST: 
            return {
                ...state,
                [`table${action.reportTable}`]: {
                    ...state[`table${action.reportTable}`],
                    isLoading: true
                },
                isLoading: true
            }

        case reportTablesConstants.GET_REFRESH_VALIDATIONS_SUCCESS: 
        case reportTablesConstants.GET_REFRESH_VALIDATIONS_FAILURE: 
            return {
                ...state,
                [`table${action.reportTable}`]: {
                    ...state[`table${action.reportTable}`],
                    isLoading: false
                },
                isLoading: false
            }

        case reportTablesConstants.DOWNLOAD_REPORT_TABLE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case reportTablesConstants.DOWNLOAD_REPORT_TABLE_SUCCESS:
        case reportTablesConstants.DOWNLOAD_REPORT_TABLE_FAILURE:
                return {
                    ...state,
                    isLoading: false
                }
        case reportTablesConstants.CHECK_IF_TABLE4_EXISTS_REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case reportTablesConstants.CHECK_IF_TABLE4_EXISTS_SUCCESS:
            return {
                ...state,
                table4:{
                    ...state.table4,
                    isTable4Exists:action.result
                },
                isLoading:false
            }
        case reportTablesConstants.CHECK_IF_TABLE4_EXISTS_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        default: return state;
    }
}