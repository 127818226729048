import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

// Import Styles
import exploreStyle from '../../assets/jss/containers/explore';

//Import Components
import ToolTip from '../../components/ToolTip/ToolTip';
import PageActions from '../../components/PageActions/PageActions';
import SearchInput from '../../components/SearchInput/SearchInput';

// global variables
import { getChildCardHeight } from '../../helpers/global'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 42,
        '&$expanded': {
            minHeight: 42,
        },
    },
    content: {
        margin: 0,
        width: "90%",
        display: 'unset',
        '&$expanded': {
            margin: 0,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}))(MuiAccordionDetails);
/**
 * ExploreMenu Container
 * @class ExploreMenu
 * @extends {Component}
 */
class ExploreMenu extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            checkedSystemFields: [],
            selectedFields: [],
            search: ''
        }
    }
    /**
     * Search menus
     * @param {*} field 
     * @param {*} value 
     */
    onSearch = (field, value) => {
        this.setState({ search: value })
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set selectedExploreKeys
     * [2]  -   Filter the menus based on search
     */
    render() {
        let { classes, exploreData, exploreKeys, checkedSystemFields, selectedFields, exploreMenuGroups, selectedMenuGroupKey, reportFilter } = this.props;

        // console.log("======== THis PROPS =========",this.props)
        let { search } = this.state;
        let selectedExploreKeys = []
        let searchExploreData = {};
        const selectedDataSets = {
            "projects_hos": "Homeownership Projects",
            "contracts_hos": "Homeownership Contracts",
            "reportYears_hos": "Homeownership Report Activity",
            "funderProjects_hos": "Homeownership Funder Settings",
            "programactivity_hos": "Homeownership Program Activity",
            "financialactivity_hos": "Homeownership Financial Activity",
            "contractactivity_hos": "Homeownership Contract Activity"
        }
        if (selectedDataSets[checkedSystemFields[0]]) {
            checkedSystemFields.forEach((value) => {
                if (selectedDataSets[value] && !checkedSystemFields.includes(selectedDataSets[value]))
                    checkedSystemFields.push(selectedDataSets[value])
            })
        }
        checkedSystemFields = [...checkedSystemFields]
        // [1]
        if (selectedMenuGroupKey.length === 0 && checkedSystemFields.length > 0) {
            switch (checkedSystemFields[0]) {
                case 'users':
                    selectedMenuGroupKey = ['group1'];
                    break;
                case 'organizations':
                    selectedMenuGroupKey = ['group1'];
                    break;
                case 'projects':
                    selectedMenuGroupKey = ['group2'];
                    break;
                case 'sites':
                    selectedMenuGroupKey = ['group2'];
                    break;
                case 'buildings':
                    selectedMenuGroupKey = ['group2'];
                    break;
                case 'units':
                    selectedMenuGroupKey = ['group2'];
                    break;
                case 'utilityAllowance':
                    selectedMenuGroupKey = ['group12'];
                    break;
                case 'reportYears':
                    selectedMenuGroupKey = ['group2'];
                    break;
                case 'funderProjects':
                    selectedMenuGroupKey = ['group2'];
                    break;
                case 'table1':
                    selectedMenuGroupKey = ['group10'];
                    break;
                case 'table2':
                    selectedMenuGroupKey = ['group2'];
                    break;
                case 'table3':
                    selectedMenuGroupKey = ['group2'];
                    break;
                case 'table4':
                    selectedMenuGroupKey = ['group2'];
                    break;
                case 'PSRC Report':
                    selectedMenuGroupKey = ['group11'];
                    break;
                case 'Asset Management Report':
                    selectedMenuGroupKey = ['group3'];
                    break;
                case 'Unit Vacancy Details':
                    selectedMenuGroupKey = ['group9'];
                    break;
                case 'Table4 Performance Measures - For Funder':
                    selectedMenuGroupKey = ['group4'];
                    break;
                case 'Table4 Performance Measures - All Funders':
                    selectedMenuGroupKey = ['group5'];
                    break;
                case 'Submission Status for Funder':
                    selectedMenuGroupKey = ['group6'];
                    break;
                case 'Tables 2 - 4 Report Summary':
                    selectedMenuGroupKey = ['group7'];
                    break;
                case 'Table 1 - Export All for Year':
                    selectedMenuGroupKey = ['group8'];
                    break;
                case 'Homeownership Projects':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'Homeownership Contracts':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'Homeownership Funder Settings':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'Homeownership Report Activity':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'Homeownership Program Activity':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'Homeownership Financial Activity':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'Homeownership Contract Activity':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'projects_hos':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'contracts_hos':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'reportYears_hos':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'funderProjects_hos':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'programactivity_hos':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'financialactivity_hos':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'contractactivity_hos':
                    selectedMenuGroupKey = ['group13'];
                    break;
                case 'Submission Status v2 (All Funders)':
                    selectedMenuGroupKey = ['group14'];
                    break;
                case 'Commerce Portfolio':
                    selectedMenuGroupKey = ['group15'];
                    break;
                case 'Race-Ethnicity Report':
                    selectedMenuGroupKey = ['group16'];
                    break;
                case 'Rent Burden Report':
                    selectedMenuGroupKey = ['group17'];
                    break;
                case 'Homeownership Submission Report':
                    selectedMenuGroupKey = ['group18'];
                    break;
                default:
                    selectedMenuGroupKey = ['group2'];
                    break;
            }
        }
        if (selectedMenuGroupKey.length > 0 && Object.keys(exploreMenuGroups).length > 0) {
            selectedMenuGroupKey.forEach(group => {
                selectedExploreKeys.push(...exploreMenuGroups[group]);
               // console.log("===== Selected Explore Keys =======", selectedExploreKeys)
            })
        }
        //uncheck bug issue
        if(selectedMenuGroupKey.length === 0  ){
            selectedExploreKeys.push('Homeownership Submission Report')
        }
        // {2}
        if (exploreKeys.length > 0 && search) {
            let regex = new RegExp(search, 'i')
            exploreKeys.forEach(key => {
                searchExploreData[key] = exploreData[key].filter(elem => regex.test(elem.title));
            })
            exploreKeys = Object.keys(searchExploreData)
            exploreKeys = exploreKeys.filter(elem => regex.test(elem) || searchExploreData[elem].length > 0)
        } else {
            searchExploreData = {...exploreData};
            exploreKeys = Object.keys(searchExploreData)
        }

       // console.log("===== Explore Keys =======", exploreKeys, searchExploreData, selectedFields, selectedExploreKeys)
        
        if (Object.keys(searchExploreData).length && reportFilter != ''){

              
              if (searchExploreData['Race-Ethnicity Report']) {

                let filterArray =['City', 'County', 'Zipcode', 'Ownership Organization', 'Property Management Organization'] ;
              //['City', 'County','Funder','Zipcode', 'Ownership Organization', 'Property Management Organization','State']
              const index = filterArray.indexOf(reportFilter);
              if (index > -1) {
                filterArray.splice(index,1)
              }
                searchExploreData['Race-Ethnicity Report'] = searchExploreData['Race-Ethnicity Report'].filter((el) => {return !filterArray.find((obj) => {         
                    return  el.title == obj
                  })})
              }

              if (searchExploreData['Rent Burden Report']) {
                let filterArray = ['City', 'County','Funder','Zipcode', 'Ownership Organization', 'Property Management Organization','State'];
                const index = filterArray.indexOf(reportFilter);
                
                if (index > -1) {
                filterArray.splice(index,1)
                }
                
                searchExploreData['Rent Burden Report'] = searchExploreData['Rent Burden Report'].filter((el) => {return !filterArray.find((obj) => {         
                    return  el.title == obj
                  })})
              }

        } 


        // else if (Object.keys(searchExploreData).length && reportFilter == '') {

        //     alterExploreData = {...searchExploreData};
        //     let filterArray = ['City', 'County', 'Zipcode', 'Ownership Organization', 'Property Management Organization'];
        //     searchExploreData['Race-Ethnicity Report'] = searchExploreData['Race-Ethnicity Report'].filter((el) => {return !filterArray.find((obj) => {         
        //         return  el.title == obj
        //       })})

        // }
        //handle disabled fields
        // let RentBurder_filterArray = ['City', 'County','Funder','Zipcode', 'Ownership Organization', 'Property Management Organization','State'];
        // if(RentBurder_filterArray.includes(reportFilter) && selectedExploreKeys.length===0){
        //     selectedExploreKeys.push('Rent Burden Report')
        // }

        return (
            <div className={`${classes.menuContainer} exploreMenu`} style={{ height: getChildCardHeight() - 67 }}>
                <div className={classes.menuHeaderContainer}>
                    <div className={classes.menuHeaderWrapper}>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <div className={classes.menuHeader}>Select Columns</div>
                            </Grid>
                            <Grid item xs={12} sm={4} className="exploreMenuContainer" >
                                <PageActions
                                    actions={['PREVIEWLINK']}
                                    handlePreviewActionClick={this.props.handlePreview}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.search}>
                        <SearchInput
                            placeholder={'Search Here'}
                            value={search}
                            field='exploreSearch'
                            onInputChangeRequest={this.onSearch}
                            onEnter={this.onSearch}
                        />
                    </div>
                </div>
                {
                    exploreKeys.length > 0 &&
                    <div className={classes.menuCheckBoxContainer} style={{ height: getChildCardHeight() - 183 }}>
                        {exploreKeys.map((key, kIndex) => {
                            return (<Accordion square key={`${key}_${kIndex}`}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls="additional-actions1-content"
                                    id="additional-actions1-header"
                                >
                                    <ToolTip title={key.replace(/^\w/, (c) => c.toUpperCase())} placement="right">
                                        <FormControlLabel
                                            aria-label={key}
                                            onClick={(event) => { event.stopPropagation() }}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox
                                                color="primary"
                                                checked={checkedSystemFields.some(elem => elem === key)}
                                                onChange={() => {
                                                    this.props.handleKeyCheckBox(key);
                                                }}
                                                disabled={selectedExploreKeys.length > 0 && !selectedExploreKeys.some(elem => elem === key)}
                                            />}
                                            label={key.replace(/^\w/, (c) => c.toUpperCase())}
                                        />
                                    </ToolTip>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    {               
                                        searchExploreData && searchExploreData[key].map((item, iIndex) => {
                                            // console.log("searchExploreData==============",key, item, iIndex)
                                            
                                            let fieldFilters = key==="Race-Ethnicity Report"?['City', 'County', 'Zipcode', 'Ownership Organization', 'Property Management Organization']:['City', 'County','Funder','Zipcode', 'Ownership Organization', 'Property Management Organization','State']
                                           
                                            return (
                                                <div key={`${item.title}_${iIndex}`}>
                                                    <ToolTip title={item.title} placement="right">
                                                        <FormControlLabel
                                                            aria-label={item.title}
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={<Checkbox
                                                                color="primary"
                                                                checked={((key == "Race-Ethnicity Report" || key== "Rent Burden Report") && fieldFilters.indexOf(item.title) != -1 ) || selectedFields.some(elem => elem.field === item.field && (elem.collection === key || selectedDataSets[elem.collection] === key))}
                                                                onChange={() => {
                                                                    this.props.handleFieldCheckBox(item);
                                                                }}
                                                                disabled={((key == "Race-Ethnicity Report" || key== "Rent Burden Report") && fieldFilters.indexOf(item.title) != -1) ||  selectedExploreKeys.length > 0 && !selectedExploreKeys.some(elem => elem === key || selectedDataSets[elem.collection] === key)}
                                                            />}
                                                            label={item.title}
                                                        />
                                                    </ToolTip>
                                                </div>
                                            )
                                        })
                                    }
                                </AccordionDetails>
                            </Accordion>)
                        })}
                    </div>
                }
            </div>
        )
    }
}
/**
 * Bind Component Property Types
 */
ExploreMenu.propTypes = {
    explore: PropTypes.array.isRequired,
    noDataErrorMessage: PropTypes.string.isRequired,
};

/*Default Props */
ExploreMenu.defaultProps = {
    explore: [],
    exploreData: [],
    onChange: () => { },
    selectedColumns: [],
    noDataErrorMessage: '',
    exploreMenuGroups: {},
    selectedMenuGroupKey: [],
    exploreKeys: []
}
//Export Component
export default (withStyles(exploreStyle)(ExploreMenu));