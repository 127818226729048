import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

//Actions
import { confActions } from '../../actions/configuration';
import { exploreActions } from '../../actions/explore';
import { reportTablesActions } from '../../actions/reportTables';
import { helpActions, alertActions, dashboardActions } from '../../actions';

//import helpers
import ValidatorForm from "../../helpers/fieldValidations";
import { globalVariable, getUserAccessForPage, history, filterSearchFields, getUserId, getUserOrgId, getUserType } from '../../helpers';
import { dashboardDownloadReportTypes, orgTypesConstants, rentBurderFilters, prrFilters } from '../../helpers/appConstants'

// SVG Icons
import { DownloadIcon } from '../../components/SvgIcons/SvgIcons';

// import component
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import DropDown from '../../components/DropDown/DropDown';
import TableComponent from '../../components/Table/TableComponent';

// Import Styles
import dashboardStyle from '../../assets/jss/containers/dashboard';

// Custom Variables
let sFields = [];
let sValues = [];
const fieldFilters = [
    "Contract/OID",
    "Site",
    "Building",
    "Building BIN",
    "Unit",
    "Household Name",
    "Move-In Date",
    "Previous Certification Date",
    "Current Recertification Date",
    "Move-out Date",
    "Vacant?",
    "Special Needs",
    "Restricted Percentage",
    "Household Size",
    "Maximum Annual Household Income",
    "Current Annual Gross Income",
    "Square Footage",
    "No of Bedrooms",
    "Maximum Allowable Rent",
    "Utility Allowance",
    "Resident Payment Amount",
    "Total Rent With Utility Allowance",
    "Unit Designation",
    "Move-In Household Size",
    "Move-In Restricted Percentage",
    "Move-In Annual Gross Income",
    "Move-In Maximum Allowable Income",
    "Current MONTHLY Gross Income",
    "Actual AMI %",
    "Subsidy Amount",
    "Subsidy Type",
    "Utility Allowance Source",
    "Total Rent With UA and Subsidy",
    "Move-out Reason",
    "Eviction Reason",
    "New Housing Situation",
    "Is HOME Unit?",
    "Maximum Allowed HOME Rent",
    "Tenant before HOME?",
    "HOME Unit Occupancy",
    "HOME Category",
    "HOME Race",
    "HOME Ethnicity - Hispanic or Latino",
    "HOME Type of Household",
    "Is NHTF Unit?",
    "Maximum Allowed NHTF Rent",
    "Is PSH Unit?",
    "Comments",
    "Validation Status",
    "Validation Issue",
    "Transfer Message",
    "Last Modified Date",
    "Last Modified By"
];

/**
 *DashboardReports
 *
 * @class DashboardReports
 * @extends {Component}
 */
class DashboardReports extends Component {
    /**
     *Creates an instance of DashboardReports.
     * @param {*} props
     * @memberof DashboardReports
     */
    constructor(props) {
        super(props);
        this.state = {
            downloadFlag: true,
            viewFlag: false,
            project: {},
            site: {},
            year: {},
            reportType: {},
            summaryDetails: {},
            reportsName: [],
            reportName: '',
            reportFilter: '',
            user_id: getUserId(),
            org_id: getUserOrgId(),
            previousHash:null
        }
        this.pageAccess = getUserAccessForPage('explore');
        this.userRole = getUserType();

    }

    /*component will mount
      */
    UNSAFE_componentWillMount() {
        const { explore } = this.props;
        const { page } = explore;
        let { reportType } = this.state;
        this.props.getHelp();
        if (this.userRole === orgTypesConstants.FUNDER) {
            if (page.skip) {
                sFields = page.sFields;
                sValues = page.sValues;
                this.props.getAll(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues);
            } else {
                sFields = [];
                sValues = [];
                this.props.getAll(1, globalVariable.subPagetableRowSize - 3, 'report_name', 'asc', '', '');
            }
        }

        if (this.userRole === orgTypesConstants.FUNDER) {
            reportType = {
                value: 0,
                label: "Funder Data Export"
            }
        } else {
            reportType = {
                value: 1,
                label: "Project Summary Report"
            }
        }

        this.setState({
            reportType: reportType
        })

        this.intervalId = setTimeout(() => {
            const { explore } = this.props;
            const { page } = explore;
            if (this.userRole === orgTypesConstants.FUNDER) {
                if (page.skip) {
                    sFields = page.sFields;
                    sValues = page.sValues;
                    this.props.getAll(page.skip, globalVariable.subPagetableRowSize - 3, page.orderBy, page.order, page.sFields, page.sValues);
                } else {
                    sFields = [];
                    sValues = [];
                    this.props.getAll(1, globalVariable.subPagetableRowSize - 3, 'report_name', 'asc', '', '');
                }
            }
        }, 120000);
        this.props.getProjectSummary(null, '');
        if (this.userRole === orgTypesConstants.FUNDER) this.props.getFunderReports()
    }

    /**
      * Initialize values to the state
      *
      * @param {*} nextProps]
      */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { dashboard } = nextProps;
        let { reportType, summaryDetails, reportsName } = this.state
        if (JSON.stringify(dashboard.projectSummary.summaryDetails) !== JSON.stringify(this.state.summaryDetails) && reportType.value === 1) {
            summaryDetails = dashboard.projectSummary.summaryDetails;
        }
        if (dashboard.funderDataExports.result !== "" && dashboard.funderDataExports.result !== undefined && dashboard.funderDataExports.result !== null) {
            reportsName = dashboard.funderDataExports.result && dashboard.funderDataExports.result.length > 0 ? dashboard.funderDataExports.result.filter(elem => elem.key !== 28 && elem.key !== 18) : [];
        }

        this.setState({
            summaryDetails: summaryDetails,
            reportsName: reportsName,
            reportType: reportType
        })
    }

    /*Component did Update for clearing the search*/
    componentDidUpdate() {
        const { location } = this.props;
        switch (location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                // history.replace({
                //     pathname: `${location.pathname}${this.state.previousHash}`,
                //     state: {
                //         ...location.state
                //     }
                // });
                history.replace(`${location.pathname}${this.state.previousHash}`)
                break;
            default:
                break;
        }
    }
    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleView = (e) => {
        const { project, year, reportType } = this.state;
        if (reportType.value === 1) {
            if (project.value && year.value) {
                this.props.viewProjectSummaryReport({ project_id: project._id, year: year.id, type: "html" });
                this.setState({
                    downloadFlag: false
                })
            }
        }
    };
    /**
     * handle download
     * @param {*} e 
     */
    handleDownload = (e) => {
        const { project, year, site, reportType, summaryDetails, reportsName, reportName, user_id, org_id, reportFilter } = this.state;

        if (reportType.value === 1 && project.value && year.value) {
            this.setState({
                downloadFlag: true
            })
            this.props.downloadProjectSummaryReport(summaryDetails.source);
        }

        if (reportType.value === 2)
            this.props.downloadTenantReport(project._id, site.id, year.id, fieldFilters);
        if (reportType.value === 3) this.props.downloadTable2Report(project._id, site.id, year.id);
        if (reportType.value === 4) this.props.downloadTable3Report(project._id, site.id, year.id);
        if (reportType.value === 5) this.props.downloadTable4Report(project._id, site.id, year.id);

        if (reportType.value === 0) {
            let selectedReportName = reportsName.filter(item => item.key === reportName)
            let reportData = {
                ReportId: reportName,
                year: year.value,
                selectedReportName: selectedReportName[0].title,
                reportFilter: reportFilter
            }
            this.props.downloadFunderReport(reportData, user_id, selectedReportName[0].title, org_id);
        }
        this.handleClearFilterActionClick();
    };

    /**
      * Handle Clear Fileter Action
      */
    handleClearFilterActionClick = () => {
        this.setState({ reset: true });
        sFields = [];
        sValues = [];
        const { explore } = this.props;
        this.props.getAll(explore.page.skip, explore.page.limit, explore.page.orderBy, explore.page.order, [], []);
        // this.props.resetFilter();
    };
    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { explore } = this.props;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.getAll(1, globalVariable.tableRowSize, explore.page.orderBy, explore.page.order, sFields, sValues);
    };
    /**
     * On change in table
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { explore } = this.props;
        this.props.getAll(skip, limit, explore.page.orderBy, explore.page.order, sFields, sValues);
    }
    /**
     * Sorting
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { explore } = this.props;
        this.props.getAll(explore.page.skip, explore.page.limit, orderBy, order, sFields, sValues);
    };
    /**
     * Handle Table Delete Action
     * @param {*} id
     */
    handleTableDeleteAction = (id) => {
        this.props.deleteExploreData(id)
        setTimeout(() => {
            this.props.clearErrorAlert()
        }, 3000)
    };
    /**
     * Handle Download Action
     * @param {*} item
     */
    handleDownloadAction = (item) => {
        if (item.downloadStatus === "InProgress") {
            this.props.showErrorAlert("Please wait... Download is in Progress...");
        } else {
            this.props.downloadExploreReport(item._id, null, item.report_name);
        }
        setTimeout(() => {
            this.props.clearErrorAlert()
        }, 3000)
    }
    /**
     * Handle change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    }
    /**
     * Handle project search
     * @param {*} search 
     */
    handleProjectsSearch = (search) => {
        this.props.getProjectSummary(null, search);
    }

    /**
     * Disable download with multiple conditions
     */
    disabledDownloadButton = () => {
        let { project, year, reportType, reportName, reportFilter } = this.state;
        switch (reportType.value) {
            case 0:
                if ((reportName === 26 && reportFilter && year.value) || (reportName === 5 && reportFilter && year.value)) {
                    return false;
                } else if (reportName && reportName !== 26 && year.value) {
                    if (reportName && reportName === 5 && year.value){
                        return true;
                    }
                    return false;
                } else {
                    return true
                }
            default:
                if (project.value && year.value) {
                    return false
                } else {
                    return true
                }
        }
    }

    /**
     *render HTML
     *
     * @returns
     * @memberof DashboardReports
     */
    render() {
        let { classes, dashboard, pageAccess, explore, location: { pathname } } = this.props;
        const { projects, years, projectsCount } = dashboard.projectSummary;
        let { project, site, year, reportType, summaryDetails, reportsName, reportName, reportFilter } = this.state;

        const fieldFilters = explore.page ? explore.page.fieldFilters : {};
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        let reportTypes = dashboardDownloadReportTypes
        if (this.userRole !== orgTypesConstants.FUNDER) {
            reportTypes = dashboardDownloadReportTypes.filter(elem => elem.value !== 0);
        }
        let reportTypeGridSize = 0;
        let reportNameGridSize = 0;
        let projectGridSize = 0;
        let siteGridSize = 0;
        let yearGridSize = 0;
        let filterGridSize = 0;
        let buttonGridSize = 3;
        switch (reportType.value) {
            case 0:
                if (reportName) {
                    if (reportName === 26 || reportName === 5) {
                        reportTypeGridSize = 2;
                        reportNameGridSize = 3;
                        yearGridSize = 2;
                        filterGridSize = 2
                    } else {
                        reportTypeGridSize = 3;
                        reportNameGridSize = 3;
                        yearGridSize = 3;
                    }
                } else {
                    reportTypeGridSize = 4;
                    reportNameGridSize = 5;
                }
                break;
            case 1:
                if (project.value) {
                    reportTypeGridSize = 3;
                    projectGridSize = 3;
                    yearGridSize = 3;
                } else {
                    reportTypeGridSize = 4;
                    projectGridSize = 5;
                }
                break;
            case 2:
            case 3:
            case 4:
            case 5:
                if (project.value && site.value) {
                    reportTypeGridSize = 2;
                    projectGridSize = 3;
                    siteGridSize = 2;
                    yearGridSize = 2;
                } else if (project.value) {
                    reportTypeGridSize = 3;
                    projectGridSize = 3;
                    siteGridSize = 3;
                } else {
                    reportTypeGridSize = 4;
                    projectGridSize = 5;
                }
                break;
            default:
                reportTypeGridSize = 9
                break;
        }
        delete classes.formControl;
        delete classes.tab;
        delete classes.cardHeader;
        delete classes.DashboardContainer;
        delete classes.PSBUCountCard;
        delete classes.complianceIssueContainer;
        delete classes.occupancyRateContainer;
        delete classes.usersSummaryContainer;
        delete classes.utilityAllowanceContainer;
        delete classes.sliderposition;
        return (
            <div className ={this.userRole === orgTypesConstants.FUNDER ? "" : 'DashboardReports' } >
                <div className='ReportDownloadBox d_down_report'>
                    {/* <div className="d_down_report"> */}
                    <ValidatorForm
                        name="projectSummaryForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }} >
                        <Grid container spacing={4}>
                            <Grid item xs={reportTypeGridSize}>
                                <AutoComplete
                                    name="reportType"
                                    suggestions={reportTypes}
                                    className="autocomplete-fixed-report-type"
                                    handleAutoCompolete={(data) => {
                                        this.setState({ reportType: data, summaryDetails: {}, reportName: "", project: {}, site: {}, year: {}, downloadFlag: false })
                                    }}
                                    selectedValue={reportType}
                                    disabled={false}
                                    placeholder="Report Type"
                                />
                            </Grid>
                            {(reportType.value === 0) &&
                                <Grid item xs={reportNameGridSize}>
                                    <DropDown
                                        name='reportName'
                                        value={reportName}
                                        label="Select the Report Title"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                    >
                                        {reportsName && reportsName.map(rec => {
                                            return <MenuItem value={rec.key} key={rec.key}>{rec.title}</MenuItem>
                                        })}
                                    </DropDown>
                                </Grid>}
                            {(reportType.value || reportType.value === 1) && <Grid item xs={projectGridSize} style = {{marginTop : "3px "}}>
                                <AutoComplete
                                    name="project"
                                    suggestions={projects}
                                    className="autocomplete-fixed"
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            project: selectedOption,
                                            summaryDetails: {},
                                            site: {},
                                            year: {}
                                        });
                                    }}
                                    selectedValue={project}
                                    disabled={false}
                                    placeholder="Select Project"
                                    dataCount={projectsCount}
                                />
                            </Grid>}
                            {(project.value && (reportType.value === 2 || reportType.value === 3 || reportType.value === 4 || reportType.value === 5)) && <Grid item xs={siteGridSize}>
                                <AutoComplete
                                    name="site"
                                    className="p-t-4"
                                    suggestions={project.sites && project.sites.length > 1 ? [{ id: 0, label: "All Sites", name: "All Sites", value: "yes" }, ...project.sites] : project.sites}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            site: selectedOption,
                                            year: {},
                                            summaryDetails: {}
                                        });
                                    }}
                                    selectedValue={site}
                                    disabled={false}
                                    placeholder="Select Site"
                                />
                            </Grid>}
                            {(reportName || (reportType.value === 1 && project.value) || (project.value && site.value)) && <Grid item xs={yearGridSize}>
                                <AutoComplete
                                    name="dashboardReportYear"
                                    className="p-t-4"
                                    suggestions={global._.sortBy(years, 'label').reverse()}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            year: selectedOption,
                                            summaryDetails: {}
                                        })
                                    }}
                                    selectedValue={year}
                                    disabled={false}
                                    placeholder="Select Year"
                                />
                            </Grid>}
                            {((reportName && reportName === 26) || ( reportName && reportName === 5)) && <Grid item xs={filterGridSize}>
                                <DropDown
                                    name='reportFilter'
                                    value={reportFilter}
                                    label="Select Filter"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                >
                                    {(reportName && reportName === 26) && rentBurderFilters && rentBurderFilters.map(rec => {
                                        return <MenuItem value={rec} key={rec}>{rec}</MenuItem>
                                    })}

                                    {(reportName && reportName === 5) && prrFilters && prrFilters.map(rec => {
                                        return <MenuItem value={rec} key={rec}>{rec}</MenuItem>
                                    })}

                                </DropDown>
                            </Grid>}
                            <Grid item xs={buttonGridSize} className="textRight">
                                {
                                    reportType.value === 1 && !summaryDetails.source ? (
                                        <Button
                                            disabled={!((project.value || reportName) && year.value)}
                                            variant="contained" color="secondary"
                                            className={classes.button}
                                            style={{ marginTop: '5px' }}
                                            onClick={this.handleView}
                                        >
                                            {/* <span className="lnr lnr-download" /> &nbsp;&nbsp;  */}
                                            View Report
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={this.disabledDownloadButton()}
                                            variant="contained" color='secondary'
                                            className={classes.button}
                                            style={{ marginTop: '5px' }}
                                            onClick={this.handleDownload}
                                        >
                                            {year.value ? <DownloadIcon fill='#fff' /> : <DownloadIcon />}
                                            &nbsp;&nbsp;     Download Report
                                        </Button>
                                    )
                                }
                            </Grid>
                            {
                                (reportType.value === 1 && summaryDetails.source) ? (
                                    <CardContent style={{ width: '100%' }}>
                                        <div dangerouslySetInnerHTML={{ __html: summaryDetails.source }} />
                                    </CardContent>
                                ) : null
                            }
                        </Grid>
                    </ValidatorForm>
                    {/* </div> */}
                </div>

                {this.userRole === orgTypesConstants.FUNDER &&
                    // <div>
                    <ContentWrapper
                        classes={classes}
                        pageAccess={pageAccess}
                        needPadding={false}
                        ischildPage={true}
                        title={"Funder Data Export"}
                        handleAddActionClick={this.handleAddActionClick}
                        handleClearFilterActionClick={() => {
                            this.setState({previousHash:history.location.hash})
                            history.replace(`${pathname}#clear`);
                        }}
                        handleGotoExploreDataClick={() => {
                            history.push(`/explore/new`);
                        }}
                        handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                        handleHelpActionClick={this.handleHelpActionClick}
                    >
                        {explore.data &&
                            <TableComponent
                                page={explore.page.skip}
                                rowsPerPage={explore.page.limit}
                                count={explore.page.count}
                                header={fieldFilters.headerCols}
                                field={fieldFilters.fieldCols}
                                search={fieldFilters.searchCols}
                                actions={pageAccess}
                                links={["report_name"]}
                                isLoading={false}
                                order={explore.page.order}
                                orderBy={explore.page.orderBy}
                                data={explore.funderDataExportList}
                                onChangePageTable={this.onChangePageTable}
                                onSortRequest={this.onSortRequest}
                                onInputChangeRequest={this.onInputChangeRequest}
                                handleTableDeleteAction={this.handleTableDeleteAction}
                                handleNavigateAction={this.handleNavigateAction}
                                handleDownloadAction={this.handleDownloadAction}
                                resetSearchContent={this.props.location.hash === "#clear"}
                                paginationVisible={true}
                                disablePagination={explore.page.lastPage}
                                sFields={filterSearchFields(sFields)}
                                sValues={sValues}
                                noDataErrorMessage={"No Records Found"}
                            />
                        }
                    </ContentWrapper>
                    // </div>
                }
            </div>


        )

    }
}

/**
 * Default Props
 */
DashboardReports.defaultProps = {
    dashboard: {
        projects: [],
        years: [],
        site: [],
        title: "Funder Data Export",
        summaryDetails: {},
        pageAccess: [],
        isSubPage: false,
        classes: {}
    }
};

/**
 * Bind Component Property Types
 */
DashboardReports.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.any,
    pageAccess: PropTypes.array,
    isSubPage: PropTypes.bool,
};

// Maps state from store to props
const mapStateToProps = ({ explore, dashboard, pageAccess, help, search }, ownProps) => {
    // const columnsData = configuration.fieldFilter['exploreData'] || {};
    pageAccess = ownProps.pageAccess ? ownProps.pageAccess : pageAccess['explore'] || [];
    // explore = explore;
    const helpData = help.data[0]
    const searchData = ownProps.isSubPage ? search['explore'] || { sFields: [], sValues: [] } : search['exploreList'] || { sFields: [], sValues: [] };
    return {
        pageAccess,
        dashboard,
        explore,
        helpData,
        searchData
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        getAll: (skip, limit, orderBy, order, sFields, sValues) => dispatch(exploreActions.getFunderDataExports(skip, limit, orderBy, order, sFields, sValues, 'DashboardDownload', 'Multifamily')),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        deleteExploreData: (id) => dispatch(exploreActions.delete(id)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        downloadExploreReport: (id, reportData, reportName) => dispatch(exploreActions.downloadExploreReport(id, reportData, reportName)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 7 })),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        clearErrorAlert: () => dispatch(alertActions.clear()),
        getProjectSummary: (limit, search) => dispatch(dashboardActions.getProjectSummary(limit, search)),
        getFunderReports: () => dispatch(dashboardActions.getFunderReports()),
        downloadTenantReport: (project, site, year, fieldFilters) => dispatch(exploreActions.downloadTenantReport(project, site, year, fieldFilters)),
        viewProjectSummaryReport: (data) => dispatch(dashboardActions.viewProjectSummaryReport(data)),
        downloadProjectSummaryReport: (data) => dispatch(dashboardActions.downloadProjectSummaryReport(data)),
        downloadFunderReport: (reportData, user_id, reportName, org_id) => dispatch(exploreActions.downloadFunderReport(reportData, user_id, reportName, org_id)),
        downloadTable2Report: (project, site, year) => dispatch(exploreActions.downloadTable2Report(project, site, year)),
        downloadTable3Report: (project, site, year) => dispatch(reportTablesActions.downloadTable3Report(project, site, year)),
        downloadTable4Report: (project_id, site_id, year) => dispatch(reportTablesActions.downloadTable4Report(project_id, site_id, year))
    }
};

// export DashboardReports
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(DashboardReports));