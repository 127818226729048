import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Components
import MaterialTableComponent from '../../../components/Table/MaterialTable';
import FieldFilter from "../../../components/FieldFilter/FieldFilter";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import DatePicker from '../../../components/DatePicker/DatePicker';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import TextBox from '../../../components/TextBox/TextBox';
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";
import TableErrors from '../../../components/TableErrors/TableErrors';
import TextArea from '../../../components/TextArea/TextArea';

// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";

// Import Redux Actions and Helper Variable
import { reportTablesActions } from '../../../actions/reportTables';
import { confActions } from '../../../actions/configuration';
import { occupantsActions } from '../../../actions/occupants';
import { alertActions, exploreActions, projectsActions, printActions, validationActions } from "../../../actions";

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helpers
import { getUserRole, getUserOrgId, globalVariable, history, getSubmissionLevel, ReportLevelsConstants, calcDate, getDateDifference, filterSearchFields,convertDateWithTimeZone } from '../../../helpers';
import { configurationConstants } from "../../../helpers/appConstants";
import { Table4Service } from '../../../services';

// Custom Variables
let sFields = [];
let sValues = [];

const DEFAULT_TRANSFERUNIT = {
    fromEffectiveDate: null,
    toEffectiveDate: null,
    comments: '',
    item: null,
    site_id: null,
    building_id: null,
    unit_id: null,
    startDate: `${new Date().getFullYear()}-01-01`,
    enDate: `${new Date().getFullYear()}-12-31`
};
/**
 * BoldTextBedroom Component
 * @class BoldTextBedroom
 * @extends {Component}
 */
class BoldTextBedroom extends Component {
    render() {
        const { data: { specialNeedOption_id, bedroomsSize, household } } = this.props;
        if (specialNeedOption_id === 2 && (bedroomsSize < 3 || household < 4)) {
            return <b>{bedroomsSize}</b>
        }
        return bedroomsSize;
    }
}
/**
 * BoldTextHousehold Component
 * @class BoldTextHousehold
 * @extends {Component}
 */
class BoldTextHousehold extends Component {
    render() {
        const { data: { specialNeedOption_id, bedroomsSize, household } } = this.props;
        if (specialNeedOption_id === 2 && (bedroomsSize < 3 || household < 4)) {
            return <b>{household}</b>
        }
        return household;
    }
}
/**
 * HeadOfHouseholdName Component
 * @class HeadOfHouseholdName
 * @extends {Component}
 */
class HeadOfHouseholdName extends Component {
    render() {
        const { data: { headOfHouseholdName, vr }, handleChange } = this.props;
        const errorsInUnits = vr.map(v => v.message).join(", ");
        return <span
            onClick={() => handleChange(this.props.data, 'headOfHouseholdName')}
            title={errorsInUnits}
        >
            {headOfHouseholdName}
        </span>;
    }
}
/**
 * AMIField Component
 * @class AMIField
 * @extends {Component}
 */
class AMIField extends Component {
    render() {
        const { data: { percentageOfAreaMedianIncome_DENORM } } = this.props;
        return `${percentageOfAreaMedianIncome_DENORM}`;
    }
}
/**
 * VacantFlag Component
 * @class VacantFlag
 * @extends {Component}
 * 
 * If above 90 days need to display yellow background 
 */
class VacantFlag extends Component {
    render() {
        const { data: { isAbove90, vacantFlag, needYellowColor } } = this.props;
        if (isAbove90) {
            return <span style={{ color: "#FFC107" }}>{vacantFlag}</span>
        }
        if (needYellowColor) {
            return <span style={{
                padding: "8px", backgroundColor: "#FFC107", display: "block", width: "50%"
            }}>{""}</span>
        }
        return vacantFlag;
    }
}
/**
 * CurrentRUP Component
 * @class CurrentRUP
 * @extends {Component}
 * 
 * Highlight Current RUP if it is greater than all Funder RUP %
 */
class CurrentRUP extends Component {
    render() {
        const { data: { setAsideType_id_LIHPct, maxFunderRUP } } = this.props;
        let style = {};
        let ftStyle = { paddingLeft: "8px", position: 'relative' };
        if (maxFunderRUP) {
            style = { position: 'absolute', display: 'block', top: 0, left: 0, zIndex: 0, width: '80%', height: '100%' };
            ftStyle = { fontWeight: 'bold', ...ftStyle };
        }

        return (
            <span style={{ display: 'block', position: 'relative' }}>
                <span style={style} />
                <span style={ftStyle}>{setAsideType_id_LIHPct}</span>
            </span>
        );
    }
}
/**
 * MoveInRUP Component
 * @class MoveInRUP
 * @extends {Component}
 * 
 *  Highlight Current RUP if it is greater than all Funder RUP %
 */
class MoveInRUP extends Component {
    render() {
        const { data: { setAsideType_id_LIHPct_MoveIn, maxFunderRUP_MoveIN } } = this.props;
        let style = {};
        let ftStyle = { paddingLeft: "8px", zIndex: 2, position: 'relative' };
        if (maxFunderRUP_MoveIN) {
            style = { position: 'absolute', display: 'block', top: 0, left: 0, zIndex: 0, Zindex: 1, width: '80%', height: '100%' };
            ftStyle = { fontWeight: 'bold', ...ftStyle };
        }

        return (
            <span style={{ display: 'block', position: 'relative' }}>
                <span style={style} />
                <span style={ftStyle}>{setAsideType_id_LIHPct_MoveIn}</span>
            </span>
        );
    }
}
/**
 * LastModifiedByLastModifiedBy Component
 * @class LastModifiedByLastModifiedBy
 * @extends {Component}
 */
class LastModifiedBy extends Component {
    render() {
        const { data: { modifiedBy, isImported } } = this.props;
        return <span dangerouslySetInnerHTML={{ __html: `${isImported ? `&darr;&nbsp;` : ''}${modifiedBy}` }} />;
    }
}

export const componentMap = {
    "householdSize": BoldTextHousehold,
    "bedrooms.value": BoldTextBedroom,
    "headOfHouseholdName": HeadOfHouseholdName,
    "percentageOfAreaMedianIncome_DENORM": AMIField,
    "vacantFlag": VacantFlag,
    "setAsideType_id_LIHPct": CurrentRUP,
    "setAsideType_id_LIHPct_MoveIn": MoveInRUP,
    "modifiedBy": LastModifiedBy
}
/**
 * Table1 Component
 * @class Table1
 * @extends {Component}
 */
class Table1 extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            fieldFilter: false,
            reset: false,
            comment: '',
            unsubmitComment: '',
            type: null,
            transferUnit: {
                ...DEFAULT_TRANSFERUNIT
            },
            disableTransferButton: false,
            openComment: false,
            homeSizeChanged: false
        }

        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }
    /**
     * Handle Component Will Mount 
     */
    UNSAFE_componentWillMount =async ()=> {
        const { table1, projectData, location: { pathname } } = this.props;
        const { page } = table1;
        let query = {}
        if (pathname.includes('archives')) {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        }
        // await setTimeout(()=>{this.props.validationUpdate(projectData)},20)
        if (page.skip) {
            sFields = page.sFields;
            sValues = page.sValues;
            await this.props.getTable1Data(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues, false, projectData, query);
        } else {
            sFields = [];
            sValues = [];
            await this.props.getTable1Data(1, globalVariable.subPagetableRowSize, 'unit.common_id', 'asc', '', '', false, projectData, query);
        }
        setTimeout(() => {
            this.props.getFieldFilter('table1',projectData.reportYear);
        }, 100);


        // get config
        this.props.getConfiguration(configurationConstants.setAsideType);

        // get errors
        this.props.getTable1Errors(projectData.reportId);

        // get validation issues
        const { project_id, reportId } = projectData;
        if (project_id && reportId) {
            this.props.validationIssues(project_id, 0, reportId, "1");
        }

        // validate from date less than to date
        ValidatorForm.addValidationRule('isValidFromDate', (value) => {
            const { transferUnit: { fromEffectiveDate, toEffectiveDate } } = this.state;
            if (fromEffectiveDate && toEffectiveDate) {
                const days = getDateDifference(value, toEffectiveDate, 'days');
                return days > 1;
            }
            return false;
        });

        // validate to effective date
        ValidatorForm.addValidationRule('isValidToDate', (value) => {
            const { transferUnit: { fromEffectiveDate, toEffectiveDate } } = this.state;
            if (fromEffectiveDate && toEffectiveDate) {
                const days = getDateDifference(fromEffectiveDate, toEffectiveDate, 'days');
                return days > 1;
            }
            return false;
        });

        // validate from date less than to date
        ValidatorForm.addValidationRule('isFromDateGTMoveIn', (value) => {
            const { transferUnit: { item } } = this.state;
            if (item.moveInDate) {
                const moveInDate = moment(item.moveInDate, "MM/DD/YYYY");
                const days = getDateDifference(value, moveInDate, 'days');
                return days <= 0;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isCurrentYear', (value) => {
            const { transferUnit: { item: { report_Year } } } = this.state;
            const selYear = global.moment(value, 'MM/DD/YYYY').get('year');
            return report_Year === selYear;
        });
    }
    /**
     * Component Will Mount
     */
   UNSAFE_componentDidMount() {
        const { state: localState } = this.props.location || { pageData: {} };
        const reportYear_id_PageProp = localState.pageData.reportYear_id;
        if (!isNaN(reportYear_id_PageProp)) {
            this.props.getReportSettings(reportYear_id_PageProp);
        }
    }
    /**
     * Component Will Receive Props
     * @param {*} newProps 
     */
    UNSAFE_componentWillReceiveProps(newProps) {
        const { reportData } = newProps;
        const { comment } = this.state;

        if (comment !== reportData.table1Comments && comment === '') {
            this.setState({
                comment: reportData.table1Comments
            });
        }
        //trigger validation cron
        // let currentTime = new Date().getTime();
        // let updateTime = new Date(this.props.reportData.updatedAt).getTime();

        // var minutes = (currentTime-updateTime) / (1000 * 60).toFixed();
        // let miSec = (currentTime-updateTime).toFixed();
        // // if(minutes < 0.5){
        //     for(let i=1;i>minutes;i--){
        //     // this.props.submitReport(this.props.projectData.reportId, {});
        //     if(i==1){console.log('update***',i)}
        //     // minutes ++;
        // }
        
        

        // console.log('min++++',minutes,miSec);
        console.log(this.props)

    }
    /**
     * Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Handle Table Component On Page Change Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { table1, projectData, location: { pathname } } = this.props;
        let query = {}
        if (pathname.includes('archives')) {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        } this.props.getTable1Data(skip, limit, table1.page.orderBy, table1.page.order, sFields, sValues, false, projectData, query);
    };
    /**
     * Handle Table Component On Sort Request Event
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { table1, projectData, location: { pathname } } = this.props;
        let query = {}
        if (pathname.includes('archives')) {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        } this.props.getTable1Data(table1.page.skip, table1.page.limit, orderBy, order, sFields, sValues, false, projectData, query);
    };
    /**
     * Handle Table Component Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { table1, projectData, location: { pathname } } = this.props;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        let query = {}
        if (pathname.includes('archives')) {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        } this.props.getTable1Data(1, globalVariable.subPagetableRowSize, table1.page.orderBy, table1.page.order, sFields, sValues, false, projectData, query);
    };
    /**
     * Handle Add Action Click Event
     */
    handleAddActionClick = () => {
        history.push('/occupants/new');
    };
    /**
     * Handle Clear Filter Action Click Event
     */
    handleClearFilterActionClick = () => {
        sFields = [];
        sValues = [];
        const { table1, projectData, location: { pathname } } = this.props;
        let query = {}
        if (pathname.includes('archives')) {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        } this.props.getTable1Data(table1.page.skip, table1.page.limit, table1.page.orderBy, table1.page.order, sFields, sValues, false, projectData, query);
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {
        const { pageAccess } = this.props;
        switch (field) {
            case 'vacantFlag':
                if (item.isVacant) {
                    if (pageAccess.indexOf('TRANSFER') > -1) {
                        // clear get one data occupants details
                        this.props.clearOccupantDetails();
                        // occupant new with selected project, site, building, unit
                        history.push({
                            pathname: '/occupants/new',
                            state: {
                                page: 'occgeneral',
                                pageData: {},
                                vacantUnitData: {
                                    project_id: { id: item.project_id, value: item.project_id },
                                    site_id: { id: item.site._id, value: item.site._id },
                                    building_id: { id: item.building._id, value: item.building._id },
                                    unit_id: { id: item.unit._id, value: item.unit._id }
                                }
                            }
                        });
                    }
                }
                break;

            case 'project.name':
                history.push({
                    pathname: '/projects/' + item.project_id,
                    state: {
                        page: 'projectEdit',
                        pageData: {
                            project_id: item.project_id
                        }
                    }
                });
                break;

            case 'site.name':
                history.push({
                    pathname: '/sites/' + item.site._id,
                    state: {
                        page: 'siteEdit',
                        pageData: {
                            site_id: item.site._id
                        }
                    }
                });
                break;

            case 'building.name':
                history.push({
                    pathname: '/buildings/' + item.building._id,
                    state: {
                        page: 'buildingEdit',
                        pageData: {
                            site_id: item.site._id,
                            building_id: item.building._id
                        }
                    }
                });
                break;

            case 'unit.common_id':
                history.push({
                    pathname: '/units/' + item.unit._id,
                    state: {
                        page: 'unitEdit',
                        pageData: {
                            site_id: item.site._id
                        }
                    }
                });
                break;
            default: break;
        }
    }
    /**
    * Handle Select the Column Filter Button Action
    * @memberof Users
    */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        this.props.changeFieldFilter("table1", this.props.table1.page.fieldFilters.visibleColumns);
    };

    /** 
     * Handle Field Filter Popup On Close Event
     */
    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter("table1", this.props.table1.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }
    /**
     *A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("table1", updatedColumns);
        } else {
            this.props.changeFieldFilter("table1", [...this.props.visibleColumns, key]);
        }
    }
    /**
     *Handle Table Delete Action
     * @param {*} id 
     * @param {*} itemData 
     */
    handleTableDeleteAction = (id, itemData) => {
        this.props.deleteOccupant(id, itemData, () => {
            // call refresh method here
            const { table1, projectData, location: { pathname } } = this.props;
            let query = {}
            if (pathname.includes('archives')) {
                query = {
                    isDelete: true
                }
            } else {
                query = {
                    isDelete: false
                }
            } this.props.getTable1Data(table1.page.skip, table1.page.limit, table1.page.orderBy, table1.page.order, sFields, sValues, false, projectData, query);
        });
        Table4Service.getConvertedTable4Data(this.props&&this.props.projectData.reportId, 'projects')
    };
    /**
     * Handle Field Filter On Save Event
     */
    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter("table1", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { table1, projectData, location: { pathname } } = this.props;
            let query = {}
            if (pathname.includes('archives')) {
                query = {
                    isDelete: true
                }
            } else {
                query = {
                    isDelete: false
                }
            } this.props.getTable1Data(table1.page.skip, table1.page.limit, table1.page.orderBy, table1.page.order, sFields, sValues, false, projectData, query);
        });
    }
    /**
     * Handle Field Component On Change Event
     * @param {*} item 
     * @param {*} name 
     */
    fieldComponentOnChange = (item, name) => {
        const { pageAccess, projectData, table1Errors } = this.props;
        // for getting errors from validaitonreport collection for old reportyears
        if (item.headOfHouseholdName === undefined) {
            let unitsList = table1Errors.table1Data.allUnits;
            let unit = unitsList.filter(elem => elem.unit_id === item.unit_id)[0];
            let moveOutYear = unit.moveOutDate ? new Date(unit.moveOutDate).getFullYear() : null
            let currentYear = new Date().getFullYear()
            if (moveOutYear !== null && moveOutYear !== currentYear) {
                item.headOfHouseholdName = '<vacant>'
            } else {
                item.headOfHouseholdName = unit.headOfHouseholdName
            }
        }
        const id = item.table1Snapshot_id || item._id;
        switch (name) {
            case 'headOfHouseholdName':
                if (item.headOfHouseholdName !== '<vacant>' && item.headOfHouseholdName !== '<empty>') {
                    history.push({
                        pathname: '/occupants/' + id,
                        state: {
                            page: 'occgeneral',
                            pageData: {
                                table_id: id,
                                reportYear_id: item.reportYear_id,
                                backButton: 'true'
                            },
                            projectData
                        }
                    });
                } else {
                    if (pageAccess.indexOf('TRANSFER') > -1) {
                        // clear get one data occupants details
                        this.props.clearOccupantDetails();
                        // occupant new with selected project, site, building, unit
                        history.push({
                            pathname: '/occupants/new',
                            state: {
                                page: 'occgeneral',
                                pageData: {},
                                vacantUnitData: {
                                    project_id: { id: item.project_id, value: item.project_id },
                                    site_id: { id: item.site._id || item.site_id, value: item.site._id || item.site_id },
                                    building_id: { id: item.building._id || item.building_id, value: item.building._id || item.building_id },
                                    unit_id: { id: item.unit._id || item.unit_id, value: item.unit._id || item.unit._id }
                                },
                                projectData
                            }
                        });
                    }
                }
                break;
            default:
        }
    }
    /**
     * Table 1 on submit / unsubmit click
     * @param {*} type 
     * @returns 
     */
    handleSaveActionClick = (type) => {
        // disable submit when has hardstop
        const { validations } = this.props;
        if (validations && type === 'SUBMIT') {
            if (validations.data.table1 === 3) {
                this.props.showErrorAlert('Need to fix Hard stop errors before submit!');
                return;
            } else if (!validations.data.isPrevSubmitted) {
                this.props.showErrorAlert('Previous Year Report Not Submitted!');
                return;
            } else { }
        }

        this.setState({
            type
        });
    }
    /**
     * Handle change of input elements
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    /**
     * Pop Alert
     * @param {*} type 
     * @returns 
     */
    popAlert = (type) => {
        const { unsubmitComment } = this.state;
        const { classes, pageAccess } = this.props;
        const levelM = `${type}_${getSubmissionLevel(pageAccess)}`;
        switch (levelM) {
            case 'SUBMIT_1':
            case 'SUBMIT_2':
            case 'SUBMIT_3':
            case 'SUBMIT_4':
                return 'Submitting a Report Table will send it forward to the level after yours.  Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'
            case 'UNSUBMIT_1':
            case 'UNSUBMIT_2':
            case 'UNSUBMIT_3':
            case 'UNSUBMIT_4':
                return (
                    <Grid item xs={12} sm={12}>
                        <div style={{ paddinBottom: "12px" }}>{'Unsubmitting a Report Table will send it backward to the level before yours. Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'}</div>
                        <div style={{ minWidth: '400px' }}>
                            <TextArea
                                name="unsubmitComment"
                                label="Comment"
                                value={unsubmitComment}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                margin="dense"
                                multiline={true}
                            // disabled={isReadOly}
                            />
                        </div>
                    </Grid>
                )
            default: return null;
        }
    }
    /**
     * Handle Submit
     */
    handleSubmit = () => {
        this._saveForm();
    }
    /** Save form */
    _saveForm = () => {
        const { type } = this.state;
        const { projectData } = this.props;
        if (projectData.reportId) {
            if (type === 'SUBMIT') {
                this.props.submitReport(projectData.reportId, {
                    comment: this.state.comment,
                    table1Comments: this.state.comment
                });
                this.setState({
                    type: null
                });
            } else {
                if (this.state.unsubmitComment !== '') {
                    this.props.unSubmitReport(projectData.reportId, {
                        comment: this.state.unsubmitComment,
                        table1Comments: this.state.comment
                    });
                    this.setState({
                        type: null
                    });
                }
            }
        }
    }
    /**
     * Handle Add Action Click Event
     */
    handleMoveInActionClick = () => {
        const { pageAccess } = this.props;
        let site_id = pageAccess.filter(p => `${p}`.indexOf("site-") >= 0)[0];
        site_id = site_id ? site_id.split('-')[1] : 0;
        site_id = parseInt(site_id, 10);
        const { projectData: { project_id } } = this.props;

        // clear get one data occupants details
        this.props.clearOccupantDetails();

        history.push({
            pathname: '/occupants/new',
            state: {
                page: 'occgeneral',
                pageData: {},
                vacantUnitData: {
                    project_id: { id: project_id, value: project_id },
                    site_id: { id: site_id, value: site_id },
                    building_id: { id: 0, value: 0 },
                    unit_id: { id: 0, value: 0 }
                }
            }
        });
    };
    /** Is Comment Required */
    isCommentRequired() {
        const { transferUnit: { fromEffectiveDate, toEffectiveDate } } = this.state;
        if (fromEffectiveDate && toEffectiveDate) {
            const diff = getDateDifference(fromEffectiveDate, toEffectiveDate);
            if (diff > 2) {
                return {
                    validators: ['required'],
                    errorMessages: ['Please enter comments because the difference between Transfer FROM Effective Date and Transfer TO Effective Date is more than one day.']
                }
            }
        }
        return {};
    }
    /**
     * Table 1 on Transfer click
     * @param {*} item 
     */
    handleTransferAction = (item) => {

        const moveInYear = moment(item.moveInDate, "MM/DD/YYYY").get('year');
        let startDate = `${item.report_Year || new Date().getFullYear()}-01-01`;
        if (moveInYear === item.report_Year) {
            startDate = moment(item.moveInDate, "MM/DD/YYYY").add('days', 1);
        }

        this.setState({
            transferUnit: {
                ...this.state.transferUnit,
                item: item,
                startDate: startDate,
                enDate: `${item.report_Year || new Date().getFullYear()}-12-30`
            }
        });

        // get vacant units
        if (item.site) {
            this.props.getVacantUnit(item.site._id);
        }
    }
    /**
     * Submit Transfer Unit
     */
    submitTransferUnit = () => {
       
        const { transferUnit } = this.state;

        console.log("------------ Submit Transfer Unit -------------",transferUnit)
        const generalData = {
            moveOutDate: transferUnit.fromEffectiveDate,
            moveInDate: transferUnit.toEffectiveDate,
            building_id: transferUnit.unit_id.building_id,
            site_id: transferUnit.unit_id.site_id,
            unit_id: transferUnit.unit_id.value,
            comments: transferUnit.comments,
            from_unit_id: transferUnit.item.unit._id
        }

        const bedroomSizeChanged = (transferUnit && transferUnit.item.bedroomsSize != transferUnit.unit_id.bedroom) ? true : false;

        // disable button
        this.setState({ disableTransferButton: true });
        // call action
        this.props.transferUnit(transferUnit.item._id, generalData, bedroomSizeChanged, () => {
            this.setState({
                transferUnit: {
                    ...DEFAULT_TRANSFERUNIT
                },
                disableTransferButton: false
            });
            // refresh table search
            const { table1, projectData, location: { pathname } } = this.props;
            const { page } = table1;
            let query = {}
            if (pathname.includes('archives')) {
                query = {
                    isDelete: true
                }
            } else {
                query = {
                    isDelete: false
                }
            } 
            Table4Service.getConvertedTable4Data(this.props&&this.props.projectData.reportId, 'projects')
            if (page.skip) {
                sFields = page.sFields;
                sValues = page.sValues;
                this.props.getTable1Data(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues, false, projectData, query);
            } else {
                sFields = [];
                sValues = [];
                this.props.getTable1Data(1, globalVariable.subPagetableRowSize, 'unit.common_id', 'asc', '', '', false, projectData, query);
            }
        }, () => {
            this.setState({ disableTransferButton: false });
        });
    }
    /**
     * Format Error Message
     * @param {*} name 
     * @param {*} occupiedUnits 
     * @param {*} unitCount 
     * @param {*} categoryId 
     * @param {*} bedroom 
     * @param {*} unitType 
     * @returns 
     */
    formatErrMsg(name, occupiedUnits, unitCount, categoryId, bedroom, unitType = 'HOME') {
        const hasBedroom = bedroom ? ` with Bedroom Size <b>${bedroom}</b> ` : ' ';
        return `${name}: A total of <b>${unitCount}</b> units must be identified as <b>"${categoryId}"</b>${hasBedroom}to meet <b>${name}</b> ${unitType} program requirements. <b>${occupiedUnits}</b> units are correctly identified as <b>"${categoryId}"</b>${hasBedroom}for this reporting year.`
    }
    /**
     * Handle Download Action Click
     */
    handleDownloadActionClick = () => {
        const { projectData, siteData, table1 } = this.props;
        const fieldFilters = table1.page ? table1.page.fieldFilters : {};
        const reportData = {
            ReportId: 15,
            year: projectData.reportYear,
            reportYear_id: projectData.reportId,
            project_id: projectData.project_id,
            site_id: siteData.site._id,
            type: 1
        }
        this.props.downloadFunderReport(reportData.project_id, reportData.site_id, reportData.year, fieldFilters.headerCols);
    }
    /**
     * Get Errors
     * @returns 
     */
    getErrors() {
        const { table1Errors, categoryType } = this.props;
        let errors = [];
        if (table1Errors) {
            const { funderData, table1Data } = table1Errors;
            funderData.forEach((dt) => {
                const bid = dt._id.bedroom_id === 0 ? null : dt._id.bedroom_id;
                const occData = table1Data[`${bid}`] || { homeState: [], homeCity: [], homeCounty: [], NHTFState: [], NHTFCounty: [], NHTFCity: [] };
                // home state
                if (occData.homeState.length < dt.homeState && dt.name) {
                    errors.push({
                        msg: this.formatErrMsg(dt.name, occData.homeState.length, dt.homeState, categoryType['19'], dt.bedroom)
                    });
                }

                // home county
                if (occData.homeCounty.length < dt.homeCounty && dt.name) {
                    errors.push({
                        msg: this.formatErrMsg(dt.name, occData.homeCounty.length, dt.homeCounty, categoryType['20'], dt.bedroom)
                    });
                }

                // home city
                if (occData.homeCity.length < dt.homeCity && dt.name) {
                    errors.push({
                        msg: this.formatErrMsg(dt.name, occData.homeCity.length, dt.homeCity, categoryType['21'], dt.bedroom)
                    });
                }

                // NHTF state
                if (occData.NHTFState.length < dt.NHTFState && dt.name) {
                    errors.push({
                        msg: this.formatErrMsg(dt.name, occData.NHTFState.length, dt.NHTFState, categoryType['64'], dt.bedroom, 'NHTF')
                    });
                }

                // NHTF county
                if (occData.NHTFCounty.length < dt.NHTFCounty && dt.name) {
                    errors.push({
                        msg: this.formatErrMsg(dt.name, occData.NHTFCounty.length, dt.NHTFCounty, categoryType['65'], dt.bedroom, 'NHTF')
                    });
                }

                // NHTF city
                if (occData.NHTFCity.length < dt.NHTFCity && dt.name) {
                    errors.push({
                        msg: this.formatErrMsg(dt.name, occData.NHTFCity.length, dt.NHTFCity, categoryType['66'], dt.bedroom, 'NHTF')
                    });
                }
            })
        }
        return errors;
    }
    /**
     * Handle Table Print
     */
    onPrint = () => {
        const { table1, projectData } = this.props;
        this.props.print(1, globalVariable.subPagetableRowSize, table1.page.orderBy, table1.page.order, sFields, sValues, false, projectData);
    };
    /**
     * Handle Validation errors
     */
    validationErrors = () => {
        let { validations,table1 } = this.props;
        let totalErrors = validations.data.table1Issues || [];
        let isNextYear = validations.data.isPrevSubmitted;
        // let unitsWithHouseHold3 = table1.data.filter(item=>parseInt(item.householdSize)>=4);
        // let unitsWithHouseHold3WithHouseHoldError = unitsWithHouseHold3.map(item=>{
        //     if(item.vr.filter(item=>item.message.includes("This household is too small to qualify for Large Household Special Needs")).length>0){
        //         return item.vr.filter(item=>item.message.includes("This household is too small to qualify for Large Household Special Needs"))
        //     }else{
        //         return null
        //     }

        // })
        // let valToBeRemoved = unitsWithHouseHold3WithHouseHoldError.filter(item=>item!==null);
        // valToBeRemoved = valToBeRemoved.flat(1)
        // if(isNextYear){
        // valToBeRemoved.map(rmv=>{
        //     totalErrors = totalErrors.filter(item=>item.complaince_id!==rmv.complaince_id);
        // })
        // }
        let errorLogs = [];
        totalErrors.forEach(error => {
            let targetIndex = errorLogs.findIndex(elem => elem.msg === error.message);
            if (targetIndex >= 0) {
                errorLogs[targetIndex].units.push(error.common_id);
            } else {
                errorLogs.push({
                    msg: error.message,
                    errorType: error.errorType,
                    units: [error.common_id]
                });
            }
        })
        errorLogs.map(err => err.units.sort())
        return errorLogs
    }
    /**
     * Handle Unit Select in table error
     * @param {*} data 
     */
    handleUnit = (data) => {
        let { validations } = this.props;
        validations = validations.data.table1Issues
        validations = validations.reduce((unique, o) => {
            if(!unique.some(obj => obj.unit_id === o.unit_id && obj.message === o.message)) {
              unique.push(o);
            }
            return unique;
        },[]);
        let errorData = validations.filter(elem => elem.common_id === data)[0];
        errorData._id = errorData.table1_id;
        errorData.site = { _id: errorData.site_id }
        errorData.building = { _id: errorData.building_id }
        errorData.unit = { _id: errorData.unit_id }
        this.fieldComponentOnChange(errorData, 'headOfHouseholdName')
    }
    /**
    * Handle Commenet icon
    */
    handleCommentActionClick = () => {
        this.setState({ openComment: true });
    }
    /**
     * Render Html
     */
    render() {
        const { fieldFilter, comment, transferUnit, disableTransferButton } = this.state;
        const { userRole, userOrgId } = this;
        let { table1, columnsMap, visibleColumns, needPadding,
            location: { pathname }, isSubPage, classes, vacantUnits, table1Errors, projectData } = this.props;
        let defaultCols = [
            "site.name",
            "building.name",
            "unit.common_id",
            "headOfHouseholdName",
            "moveInDate",
            "previousCertDate",
            "recentCertDate",
            "moveOutDate",
            "vacantFlag",
            "householdStudentExceptionType_id",
            "specialNeed.name",
            "setAsideType_id_LIHPct",
            "householdSize",
            "unitSquareFootage",
            "noOfBedRooms",
            "maximumAllowableIncome",
            "maximumAllowableRent",
            "annualGrossIncome",
            "utilityAllowance",
            "residentPaymentAmount",
            "totalRent",
            "moveOutReasonId.name",
            "newHousingSituationId.name",
            "evictionReasonId.name",
        ];
        let { pageAccess } = this.props;
        const fieldFilters = table1.page ? table1.page.fieldFilters : {};

        if (projectData.reportYear < 2023 && Object.keys(fieldFilters).length > 0) {
            
            let arrHeader = ['Move-out Reason','New Housing Situation','Eviction Reason'];
            let arrFieldCols = ["moveOutReasonId.name","evictionReasonId.name","newHousingSituationId.name"]
            fieldFilters.headerCols = fieldFilters.headerCols && fieldFilters.headerCols.length >  0 ? fieldFilters.headerCols.filter((x) => { return !arrHeader.find((y) => x == y)}) :
            fieldFilters.headerCols;

            fieldFilters.fieldCols = fieldFilters.fieldCols && fieldFilters.fieldCols.length > 0 ?
            fieldFilters.fieldCols.filter((x) => {return !arrFieldCols.find((y) => x == y)}) :
            fieldFilters.fieldCols;

            fieldFilters.searchCols = fieldFilters.searchCols && fieldFilters.searchCols.length > 0 ?
            fieldFilters.searchCols.filter((x) => {return !arrFieldCols.find((y) => x == y)}) :
            fieldFilters.searchCols;
        }

        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        const isReadOly = pageAccess.indexOf('SAVE') === -1; 
        if (pageAccess.indexOf('NO_ACCESS') > -1) {
            return <h4 className="section_title textCenter">{'This table is not currently required by any funder for this report year.'}</h4>;
        }
        const levelInfo = pageAccess.length > 0 ? `Report currently at ${ReportLevelsConstants[pageAccess[pageAccess.length - 1]]} level.` : '';
        const fielderrors = this.getErrors();
        table1.data.forEach(data => {
            data.annualGrossIncome = Number(data.annualGrossIncome);
            data.createdAt = data.createdAt!==null?data.createdAt:null;
            data.moveInDate = data.moveInDate!==null?data.moveInDate:null;
            data.moveInDateAct = data.moveInDateAct!==null?data.moveInDateAct:null;
            data.previousCertDate = data.previousCertDate!==null?data.previousCertDate:null;
            data.recentCertDate = data.recentCertDate!==null?data.recentCertDate:null;
            data.updatedAt = data.updatedAt!==null?data.updatedAt:null;
            data.transferDate = data.transferDate!==null?data.transferDate:null;
            data.transferFrom = data.transferFrom!==null?data.transferFrom:null;
            data.transferOn = data.transferOn!==null?data.transferOn:null;
            data.moveOutDate = data.moveOutDate!==null?data.moveOutDate:null;
        });
        const stickyHeaders = fieldFilters.headerCols.slice(0, 4)
        return (
            <ContentWrapper
                ref={el => (this.container = el)}
                classes={classes}
                pageAccess={['COMMENT', 'PRINT', 'DOWNLOAD', ...pageAccess]}
                isSubPage={isSubPage}
                ischildPage={true}
                title={levelInfo}
                titleCss={'bolderText'}
                pageName="reports"
                validationContent={table1.validations}
                handleAddActionClick={this.handleAddActionClick}
                needPadding={needPadding}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleMoveInActionClick={this.handleMoveInActionClick}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleSubmitActionClick={() => this.handleSaveActionClick('SUBMIT')}
                handleUnSubmitActionClick={() => this.handleSaveActionClick('UNSUBMIT')}
                handleDownloadActionClick={this.handleDownloadActionClick}
                handleCommentActionClick={this.handleCommentActionClick}
                handleRefreshValidationsClick={() => {
                    const { projectData } = this.props;
                    this.props.refreshValidations(projectData.project_id, 0, projectData.reportId, "1");
                }}
                handlePrintActionClick={this.onPrint}
                titleBg={'white'}
            >
                <TableErrors
                    fielderrors={fielderrors || []}
                    data={table1Errors}
                    errorPage={'table1'}
                    classes={{
                        action: classes.action,
                        message: classes.message,
                        root: classes.root
                    }}
                    validations={this.validationErrors()}
                    handleUnitSelect={(data, content) => {
                        this.handleUnit(data)
                    }}
                />
                <MaterialTableComponent
                    container={this.container}
                    page={table1.page.skip}
                    rowsPerPage={table1.page.limit}
                    count={table1.page.count}
                    stickyHeaders={stickyHeaders}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    actions={pageAccess}
                    actionClassName={'actions-end'}
                    links={['headOfHouseholdName', 'vacantFlag']}
                    order={table1.page.order}
                    orderBy={table1.page.orderBy}
                    isLoading={table1.isLoading}
                    boldText={['annualGrossIncome']}
                    data={table1.data}
                    highlightField={['headOfHouseholdName']}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    paginationVisible={true}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    noDataErrorMessage={"No Records Found"}
                    currentPage={'table1'}
                    handleTransferAction={this.handleTransferAction}
                    componentMap={componentMap}
                    showLoading={false}
                    deleteTooltip={'Delete Household'}
                    getDeleteTitle={(delItemData) => {
                        let { unit, headOfHouseholdName } = delItemData;
                        unit = unit || {};
                        return (
                            <div>
                                Are you sure you want to delete the occupant <b>{headOfHouseholdName}</b> in
                                <b> Unit: {unit.common_id}</b>?
                            </div>
                        )
                    }}
                />
                <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                    <FieldFilter
                        open={fieldFilter}
                        handleOnClose={this.handleFieldFilterOnClose}
                        handleOnChange={this.handleFieldFilterOnChange}
                        handleOnSave={this.handleFieldFilterOnSave}
                        columnsMap={columnsMap}
                        visibleColumns={visibleColumns}
                        disabledCols={defaultCols}
                    />
                    <AlertDialog
                        open={this.state.type !== null}
                        title={'Warning:'}
                        onSave={() => this.refs.form.submit()}
                        onClose={() => {
                            this.setState({ type: null })
                        }}
                        saveText={'Submit'}
                    >
                        {this.popAlert(this.state.type)}
                    </AlertDialog>
                    <AlertDialog
                        open={transferUnit.item !== null}
                        title="Unit Transfer:"
                        onClose={() => {
                            this.setState({
                                transferUnit: {
                                    ...DEFAULT_TRANSFERUNIT
                                }
                            })
                        }}
                        onSave={() => {
                            this.refs.transferForm.submit();
                        }}
                        saveText={'OK'}
                        buttonDisable={disableTransferButton}
                    >
                        <Grid item xs={12} sm={12}>
                            {
                                transferUnit.item && (
                                    <ValidatorForm
                                        name="moveOutTransfer"
                                        ref="transferForm"
                                        autoComplete="off"
                                        onSubmit={this.submitTransferUnit}
                                        onError={errors => { }} >
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={6}>
                                                <TextBox
                                                    name="unitFrom"
                                                    value={`${transferUnit.item.unit.common_id} - ${transferUnit.item.building.name} - ${transferUnit.item.headOfHouseholdName}`}
                                                    label="Unit Moving From"
                                                    className={classes.textField}
                                                    handleChange={this.handleChange}
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <DatePicker
                                                    name="fromEffectiveDate"
                                                    value={transferUnit.fromEffectiveDate}
                                                    label="Transfer FROM Effective Date"
                                                    className={classes.textField}
                                                    handleChange={(name, value) => {
                                                        this.setState({
                                                            transferUnit: {
                                                                ...transferUnit,
                                                                fromEffectiveDate: value,
                                                                toEffectiveDate: moment(value).add(1, 'd')
                                                            }
                                                        });
                                                    }}
                                                    margin="dense"
                                                    validators={['required', 'isValidFromDate', 'isFromDateGTMoveIn', 'isCurrentYear']}
                                                    errorMessages={['Please enter valid Transfer FROM Effective Date', 'Date must be less than To Effective date', 'Date must be greater than Unit move in date', 'Date should be in current report year']}
                                                    validations={{
                                                        minDate: moment(transferUnit.startDate),
                                                        maxDate: moment().endOf('year')
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <AutoComplete
                                                    placeholder="Unit Number Moving TO"
                                                    suggestions={vacantUnits}
                                                    handleAutoCompolete={(selectedOption) => {
                                                        this.setState({
                                                            transferUnit: {
                                                                ...transferUnit,
                                                                unit_id: selectedOption
                                                            }
                                                        });
                                                    }}
                                                    selectedValue={transferUnit.unit_id}
                                                    validators={['required']}
                                                    errorMessages={['Please select Unit Number Moving TO']}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <DatePicker
                                                    name="toEffectiveDate"
                                                    value={transferUnit.toEffectiveDate}
                                                    label="Transfer TO Effective Date:"
                                                    className={classes.textField}
                                                    handleChange={(name, value) => {
                                                        this.setState({
                                                            transferUnit: {
                                                                ...transferUnit,
                                                                toEffectiveDate: value
                                                            }
                                                        });
                                                    }}
                                                    margin="dense"
                                                    validators={['required', 'isValidToDate', 'isCurrentYear']}
                                                    errorMessages={['Please enter valid Transfer TO Effective Date', 'Date must be greater than FROM Effective date', 'Date should be in current report year']}
                                                    disabled={transferUnit.fromEffectiveDate === null}
                                                    validations={{
                                                        minDate: transferUnit.fromEffectiveDate ? calcDate(transferUnit.fromEffectiveDate, 'add', { days: 1 }) : null,
                                                        maxDate: moment().endOf('year')
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextBox
                                                    name="comments"
                                                    value={transferUnit.comments}
                                                    label="Comments"
                                                    // validators={['required']}
                                                    errorMessages={['Please enter Comments']}
                                                    className={classes.textField}
                                                    handleChange={(name, value) => {
                                                        this.setState({
                                                            transferUnit: {
                                                                ...transferUnit,
                                                                comments: value
                                                            }
                                                        });
                                                    }}
                                                    {...this.isCommentRequired()}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                )
                            }
                        </Grid>
                    </AlertDialog>
                    <AlertDialog
                        open={this.state.openComment}
                        title="Comment"
                        onSave={() => this.setState({ openComment: false })}
                        onClose={() => this.setState({ openComment: false })}
                        saveVisible={!isReadOly}
                        saveText={'Submit'}
                    >
                        <TextArea
                            name="comment"
                            label="Comments"
                            value={comment || ''}
                            className={`${classes.textField} width400`}
                            handleChange={this.handleChange}
                            margin="dense"
                            disabled={isReadOly}
                            multiline={true}
                        />
                    </AlertDialog>
                </ValidatorForm>
            </ContentWrapper>
        );
    }
}
/**
 * Set Props Types
 */
Table1.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    pageAccess: PropTypes.array
};
/**
 * Default Props
 */
Table1.defaultProps = {
    needPadding: true,
    isSubPage: false,
    title: '',
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['table1'] || {};
    const homeCategory = state.configuration.configurations[configurationConstants.setAsideType].filter(st => st.category_id === 5 || st.category_id === 6);
    let categoryType = {};
    homeCategory.map(hc => categoryType[hc.key] = hc.name);
    return {
        pageAccess: state.pageAccess['table1'] || [],
        table1: state.reportTables.table1,
        siteData: state.reportTables.table1.data[0],
        columnsMap: columnsData.columnsMap || {},
        defaultCols: columnsData.defaultCols || [],
        visibleColumns: columnsData.visibleColumns || [],
        projectData: ownProps.projectData,
        vacantUnits: state.occupants.transferUnit.vacantUnits,
        table1Errors: state.reportTables.formValidations.table1.data,
        categoryType: categoryType,
        validations: { data: state.reportTables.validationIssues },
        reportData: state.projects.reportSettings.data || { table1Comments: "" }
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getTable1Data: (skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData, query) => dispatch(reportTablesActions.getTable1Data(skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData, query)),
        validationUpdate:(projectData)=>dispatch(validationActions.updateValidations(projectData.project_id, 0, projectData.reportId, 0)),
        deleteOccupant: (id, item, cb) => dispatch(occupantsActions.deleteOccupant(id, item, cb)),
        getFieldFilter: (table,year) => dispatch(confActions.getFieldFilter(table,year)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        submitReport: (id, formData) => dispatch(reportTablesActions.submitTable(id, formData, 1)),
        unSubmitReport: (id, formData) => dispatch(reportTablesActions.unSubmitTable(id, formData, 1)),
        getVacantUnit: (site_id) => dispatch(occupantsActions.getVacantUnit(site_id)),
        transferUnit: (table_id, data, bedroomSizeChanged, cb, ecb) => dispatch(occupantsActions.transferUnit(table_id, data, bedroomSizeChanged, cb, ecb)),
        getTable1Errors: (id) => dispatch(reportTablesActions.getTable1Errors(id)),
        getConfiguration: (name) => dispatch(confActions.getConfiguration(name)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        validationIssues: (project_id, site_id, reportYear_id, reportTable) => dispatch(reportTablesActions.getIssues(project_id, site_id, reportYear_id, reportTable)),
        clearOccupantDetails: () => dispatch(occupantsActions.clearOccupantInLocal()),
        downloadFunderReport: (project, site, year, fieldFilters) => dispatch(exploreActions.downloadTenantReport(project, site, year, fieldFilters, 'WBARS_Table1Export.xlsx')),
        getReportSettings: (id) => dispatch(projectsActions.getReportSettings(id)),
        refreshValidations: (project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) => dispatch(reportTablesActions.refreshValidations(project_id, site_id, reportYear_id, reportTable)),
        print: (skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData) => dispatch(printActions.printTable1Data(skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData)),
    }
};
/** Export Components */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Table1));
