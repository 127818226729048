import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";

// import child router components
import OccupantsList from "./OccupantsList";
import OccupantsView from "./OccupantsView";
import OccupantsNew from "./OccupantsNew";
/**
 * OccupantsRouter Container
 * @class OccupantsRouter
 * @extends {Component}
 */
class OccupantsRouter extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { match, projectId, isSubPage, reportId, projectData, title } = this.props;
        return (
            <Switch>
                <Route exact path={match.url} render={(props) => {
                    return <OccupantsList
                     title={title} 
                     match={props.match} 
                     page='occupants' {...props}
                      reportId={reportId} 
                      projectData={projectData} />
                }} />
                <Route path={`${match.url}/new`} render={props => {
                    return (
                        <OccupantsNew
                            {...props}
                            occupantId={null}
                            projectId={projectId}
                            isSubPage={isSubPage}
                        />
                    )
                }} />
                <Route path={`${match.url}/:id`} render={props => {
                    return (
                        <OccupantsView
                            {...props}
                            occupantId={match.params.id}
                            projectId={projectId}
                            isSubPage={isSubPage}
                        />
                    )
                }} />
            </Switch>

        );
    }
}
/**
 * Bind Component Property Types
 */
OccupantsRouter.propTypes = {
    classes: PropTypes.object,
};
/**
 * Export Component
 */
export default OccupantsRouter;
