//import constants
import { exploreConstants } from '../constants';

//import services
import { exploreService } from '../services';

//import actions
import { alertActions } from './';
import { saveAs } from 'file-saver';

export const exploreActions = {
    getCustomReportsMenu,
    updateData,
    getAll,
    delete: _delete,
    getById,
    downloadExploreReport,
    downloadTenantReport,
    downloadFunderReport,
    previewExploreReport,
    saveTemplateData,
    saveCustomTemplateData,
    downloadTable2Report,
    getFunderDataExports
};

/**
 *Get explore data dropdown fields while adding and updating 
 *
 * @returns
 */
function getCustomReportsMenu(reportYear) {
    return dispatch => {
        dispatch(request());

        exploreService.getCustomReportsMenu(reportYear)
            .then(
                explore => {
                    dispatch(success(explore.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: exploreConstants.GETALL_REQUEST } }
    function success(explore) { return { type: exploreConstants.GETALL_SUCCESS, explore } }
    function failure(error) { return { type: exploreConstants.GETALL_FAILURE, error } }
}

/**
 *Update a report in explore data
 *
 * @param {*} reportData - updated data
 * @returns
 */
function updateData(reportData) {
    return dispatch => {
        dispatch(request());
        exploreService.updateData(reportData)
            .then(
                explore => {
                    dispatch(success(explore));
                    dispatch(alertActions.success('Updated Successfully'));
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: exploreConstants.UPDATEDATA_REQUEST } }
    function success(explore) { return { type: exploreConstants.UPDATEDATA_SUCCESS, explore } }
    function failure(error) { return { type: exploreConstants.UPDATEDATA_FAILURE, error } }
}

/**
 *Save Template a report in explore data
 *
 * @param {*} reportData - updated data
 * @returns
 */
function saveTemplateData(reportData) {
    return dispatch => {
        reportData.uniqId = new Date().valueOf();
        dispatch(request());
        exploreService.saveTemplateData(reportData)
            .then(
                explore => {
                    dispatch(success(explore));
                    dispatch(alertActions.success('Updated Successfully'));
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: exploreConstants.UPDATEDATA_REQUEST } }
    function success(explore) { return { type: exploreConstants.UPDATEDATA_SUCCESS, explore } }
    function failure(error) { return { type: exploreConstants.UPDATEDATA_FAILURE, error } }
}

/**
 *Save Template a report in explore data
 *
 * @param {*} reportData - updated data
 * @returns
 */
function saveCustomTemplateData(reportData) {
    return dispatch => {
        reportData.uniqId = new Date().valueOf();
        dispatch(request());
        exploreService.saveCustomTemplateData(reportData)
            .then(
                explore => {
                    dispatch(success(explore));
                    dispatch(alertActions.success('Updated Successfully'));
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: exploreConstants.UPDATEDATA_REQUEST } }
    function success(explore) { return { type: exploreConstants.UPDATEDATA_SUCCESS, explore } }
    function failure(error) { return { type: exploreConstants.UPDATEDATA_FAILURE, error } }
}

/**
 *Get explore data listing values
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @returns
 */
function getAll(skip, limit, orderBy, order, sFields, sValues, exploreType = 'explore') {
    return dispatch => {
        dispatch(request());
        exploreService.getAll(skip, limit, orderBy, order, sFields, sValues, exploreType)
            .then(
                explore => dispatch(success(explore)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: exploreConstants.GETALL_REPORTS_REQUEST } }
    function success(explore) { return { type: exploreConstants.GETALL_REPORTS_SUCCESS, explore } }
    function failure(error) { return { type: exploreConstants.GETALL_REPORTS_FAILURE, error } }
}

/**
 *Get funder data exports
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @returns
 */
function getFunderDataExports(skip, limit, orderBy, order, sFields, sValues, exploreType = 'explore', reportType) {
    return dispatch => {
        dispatch(request());
        exploreService.getAll(skip, limit, orderBy, order, sFields, sValues, exploreType, reportType)
            .then(
                explore => dispatch(success(explore)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: exploreConstants.GETALL_FUNDERDATA_REPORTS_REQUEST } }
    function success(explore) { return { type: exploreConstants.GETALL_FUNDERDATA_REPORTS_SUCCESS, explore } }
    function failure(error) { return { type: exploreConstants.GETALL_FUNDERDATA_REPORTS_FAILURE, error } }
}

/**
 *prefixed function name with underscore because delete is a reserved word in javascript
 *
 * @param {*} id - explore id in the listing page
 * @returns
 */
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        exploreService.delete(id)
            .then(
                explore => {
                    dispatch(success(id));
                    dispatch(alertActions.success('Deleted successfully'));
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) { return { type: exploreConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: exploreConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: exploreConstants.DELETE_FAILURE, id, error } }
}


/**
 *A method to get the explore details
 *
 * @param {*} _id - explore id
 * @returns
 */
function getById(_id) {
    return dispatch => {
        dispatch(request());

        exploreService.getById(_id)
            .then(
                explore => dispatch(success(explore)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: exploreConstants.GETONE_REQUEST } }
    function success(explore) { return { type: exploreConstants.GETONE_SUCCESS, explore } }
    function failure(error) { return { type: exploreConstants.GETONE_FAILURE, error } }
}

/**
 * Download explore reports
 * 
 * @param {*} id - explore id
 * @param {*} reportData - explore data
 * @param {*} reportName - default name
 */
function downloadExploreReport(id, reportData, reportName) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait... Download is Inprogress'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)

        exploreService.downloadExploreReport(id, reportData)
            .then(res => res.blob())
            .then(
                blob => {
                    reportName = (/.xlsx/g).test(reportName) ? reportName.replace('.xlsx', '') : reportName;
                    saveAs(blob, `${reportName}.xlsx`);
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: exploreConstants.DOWNLOAD_REQUEST } }
    function success() { return { type: exploreConstants.DOWNLOAD_SUCCESS } }
    function failure(error) { return { type: exploreConstants.DOWNLOAD_FAILURE, error } }
}
/**
 * Preview explore reports
 * 
 * @param {*} data - data contains selectedColumns, filterAtributes
 */
function previewExploreReport(data) {
    return dispatch => {
        // To empty preview data if all selected columns are unselected
        if (data) {
            dispatch(request());
            // dispatch(alertActions.success('Please wait...'));

            exploreService.previewExploreReport(data)
                .then(
                    preview => dispatch(success(preview)),
                    error => {
                        dispatch(alertActions.error(error))
                        dispatch(failure(error.toString()))
                    }
                );
        } else {
            dispatch(success({}))
        }
    };
    function request() { return { type: exploreConstants.PREVIEW_REQUEST } }
    function success(preview) { return { type: exploreConstants.PREVIEW_SUCCESS, preview } }
    function failure(error) { return { type: exploreConstants.PREVIEW_FAILURE, error } }
}

/**
 * Download funder reports from dashboard
 * 
 * @param {*} reportData - funder excel data
 * @param {*} user_id- logged in user
 * @param {*} selectedReportName - default name
 * @param {*} org_id - organization_id 
 */
function downloadFunderReport(reportData, user_id, selectedReportName = '', org_id) {
    let d = new Date();
    let currDate = (d.getMonth() + 1) + '-' + d.getDate() + '-' + d.getFullYear();
    let reportName = reportData.selectedReportName + " " + currDate + '.xlsx';
    let uniqId = `${user_id}_${new Date().getTime()}`;

    return dispatch => {
        dispatch(request());
        if(reportData.module&&reportData.module==='HO'){
            exploreService.downloadFunderReport(reportData, uniqId, selectedReportName, reportName, org_id)
            .then(res => res.blob())
            .then(
                blob => {
                    dispatch(alertActions.success('Please wait... Download is Inprogress'));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                    }, 3000)
                    reportName = reportData.selectedReportName==='HO Submission Report'?'Homeownership Submission Report':reportData.selectedReportName;
                    saveAs(blob, `${reportData.year}_${reportName}.xlsx`);
                    dispatch(success());
                },
                error => {
                    dispatch(alertActions.error('This project does not exist for selected year'));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                    }, 3000)
                    dispatch(failure(error ? error.toString() : "Internal Server Error"))
                }
            );
        }else{
        exploreService.downloadFunderReport(reportData, uniqId, selectedReportName, reportName, org_id)
            //.then(res => res.blob())
            .then(
                blob => {
                    //saveAs(blob, reportName);
                    dispatch(success());
                },
                error =>{
                    dispatch(failure(error ? error.toString() : "Internal Server Error"))
                }
            );
        }
    };
    function request() { return { type: exploreConstants.DOWNLOAD_REQUEST } }
    function success() { return { type: exploreConstants.DOWNLOAD_SUCCESS } }
    function failure(error) { return { type: exploreConstants.DOWNLOAD_FAILURE, error } }
}

/**
 *Tenant report from dashboard and Table 1 report 
 *
 * @param {*} project - project_id
 * @param {*} site - site_id
 * @param {*} year - selected year
 * @param {*} fieldFilters - fields to be added in the report
 * @param {*} fileName - default file name
 * @returns
 */
function downloadTenantReport(project, site, year, fieldFilters, fileName = 'WBAR_Table1Export.xlsx') {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait...'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)

        exploreService.downloadTenantReport(project, site, year, fieldFilters)
            .then(res => res.blob())
            .then(
                blob => {
                    saveAs(blob, fileName);
                    dispatch(success());
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: exploreConstants.DOWNLOAD_REQUEST } }
    function success() { return { type: exploreConstants.DOWNLOAD_SUCCESS } }
    function failure(error) { return { type: exploreConstants.DOWNLOAD_FAILURE, error } }
}

/**
 * Table 2 Report
 * @param {*} reportYear_id 
 * @param {*} year 
 * @param {*} fileName 
 */
function downloadTable2Report(project_id, site_id, year, fileName = 'WBAR_Table2Export.xlsx') {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait...'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)

        exploreService.downloadTable2Report(project_id, site_id, year)
            .then(res => res.blob())
            .then(
                blob => {
                    saveAs(blob, `${year}_${fileName}`);
                    dispatch(success());
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: exploreConstants.DOWNLOAD_REQUEST } }
    function success() { return { type: exploreConstants.DOWNLOAD_SUCCESS } }
    function failure(error) { return { type: exploreConstants.DOWNLOAD_FAILURE, error } }
}

