import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Import Components
import TableComponent from '../../../components/Table/TableComponent';
import { componentMap } from '../../../containers/Reports/tabs/Table1';
/**
 * Table1 Print Container
 * @class Table1
 * @extends {Component}
 */
class Table1 extends Component {
    /**
     * Render Html
     */
    render() {
        const { data } = this.props;
        const fieldFilters = data.page ? data.page.fieldFilters : {};

        return (
                <TableComponent
                    page={0}
                    rowsPerPage={data.data.length}
                    count={data.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    links={['headOfHouseholdName', 'vacantFlag']}
                    boldText={['annualGrossIncome']}
                    data={data.data}
                    highlightField={['headOfHouseholdName']}
                    noDataErrorMessage={"No Records Found"}
                    componentMap={componentMap}
                    showLoading={false}
                    searchVisible={false}
                    ellipsis={false}
                />
        );
    }
}

/**
 * Set Props Types
 */
Table1.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    pageAccess: PropTypes.array
};

/**
 * Default Props
 */
Table1.defaultProps = {
    table1: {
        data: [],
        fieldFilters: {

        }
    }
};

export default Table1
