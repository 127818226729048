import { printConstants } from '../constants/print';

const initialState = {
    isLoading: false,
    printData: null,
    printComponent: null,
    data: [],
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: [],
            searchCols: []
        }
    }
}

export function print(state = { ...initialState }, action) {

    switch (action.type) {
        case printConstants.PRINT_TABLE1_DATA_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        case printConstants.PRINT_TABLE1_DATA_SUCCESS:
            return {
                ...action.printData,
                isLoading: false,
                printComponent: action.printComponent
            };
        case printConstants.PRINT_TABLE1_DATA_FAILURE:
            return {
                ...initialState,
                error: action.error,
                isLoading: false
            };
        default: return state;
    }
}