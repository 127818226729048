import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import ReportsList from "./ReportsList";
import ReportView from "./ReportView";
/**
 * ReportsRouter Component
 * @class ReportsRouter
 * @extends {Component}
 */
class ReportsRouter extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, projectId, isSubPage, page } = this.props;
        return (
            <Switch >
                <Route exact path={match.url} render={(props) => {
                    return <ReportsList 
                    {...props} 
                    page={page} 
                    projectId={projectId} 
                    isSubPage={isSubPage}/>
                }} />
                <Route path={`${match.url}/:id/:year`} render={props => {
                    return (
                        <ReportView
                            {...props}
                            site_id={props.location.state ? props.location.state.site_id : null}
                            pageData={
                                props.location.state ? props.location.state.pageData : {}
                            }
                            projectId={projectId}
                            isSubPage={isSubPage}
                        />
                    )
                }} />

            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default ReportsRouter;
